import "./App.css";
import { BrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";

const Routing = React.lazy(() => import("./Components/Routing/Routing.js"));

function App() {

  return (
    <>

      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        }
      >
        {/* <AuthProvider> */}
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
        {/* </AuthProvider> */}
      </Suspense>

    </>
  );
}

export default App;
